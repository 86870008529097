<template>
  <div>
    <template v-if="!isDataInited">
      <vx-card :is-refresh="isLoading">
        <span>Loading...</span>
      </vx-card>
    </template>
    <template v-else>
      <vx-card :title="'#' + (spkData.header.no_spk || '')" class="mb-3" collapse-action :is-refresh="isLoading">
        <div class="vx-row">
          <div class="vx-col">
            <div class="img-container mb-4">
              <img alt="" class="w-32" src="@/assets/svg/contract.svg"/>
            </div>
          </div>
          <div class="vx-col flex-1">
            <table class="table-info">
              <tr>
                <td class="font-semibold">Jenis</td>
                <td>: {{ spkData.header.jenis_spk }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Proyek</td>
                <td>: {{ spkData.header.proyek.kode }} - {{ spkData.header.proyek.nama }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Nama Rekanan</td>
                <td>: {{ spkData.header.rekanan.nama }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Status Approval</td>
                <td>: <span class="inline-flex items-center whitespace-no-wrap justify-center p-1 text-xs text-white rounded-full" :class="['bg-' + spkData.header.status_approval_color]">{{ spkData.header.status_approval }}</span></td>
              </tr>
            </table>
          </div>
          <div class="vx-col flex-1">
            <table class="table-info">
              <tr>
                <td class="font-semibold">Status</td>
                <td>: <span class="inline-flex items-center whitespace-no-wrap justify-center p-1 text-xs text-white rounded-full" :class="['bg-' + spkData.header.status_color]">{{ spkData.header.status }}</span></td>
              </tr>
              <tr>
                <td class="font-semibold">Tgl. SPP</td>
                <td>: {{ spkData.header.tgl_spk }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Created At</td>
                <td>: {{ spkData.header.created_at }}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="vx-row mt-3">
          <div class="vx-col flex flex-wrap">
            <vs-button type="filled" color="danger" size="small" class="px-3 mr-3" icon-pack="feather" icon="icon-trash" :disabled="isApprovalApproved" @click="confirmDelete(spkData.header.id)">Hapus</vs-button>
            <vs-button type="filled" color="primary" size="small" class="px-3 mr-3" icon-pack="feather" icon="icon-printer" :disabled="isApprovalOnProgress" @click="printKontrak">Print Kontrak</vs-button>
            <vs-button type="flat" color="primary" size="small" class="px-3"  icon-pack="feather" icon="icon-refresh-cw" @click="getInitData">Refresh</vs-button>
          </div>
        </div>
      </vx-card>

      <vx-card>
        <vs-tabs alignment="fixed" v-model="activeTab">
          <vs-tab label="SPP Header">
            <TabHeader ref="tabHeader" :isActive="activeTab === 0"/>
          </vs-tab>
          <vs-tab label="Detail Pekerjaan">
            <TabPekerjaan ref="tabPekerjaan" :isActive="activeTab === 1"/>
          </vs-tab>
          <vs-tab label="SPP Adjust">
            <TabAdjust ref="tabAdjust" :isActive="activeTab === 2"/>
          </vs-tab>
          <vs-tab label="Progress">
            <TabProgress ref="tabProgress" :isActive="activeTab === 3"/>
          </vs-tab>
          <vs-tab label="Informasi Pembayaran">
            <TabPembayaran ref="tabPembayaran" :isActive="activeTab === 4"/>
          </vs-tab>
          <vs-tab label="Approvals">
            <TabApprovals ref="tabApprovals" :isActive="activeTab === 5"/>
          </vs-tab>
        </vs-tabs>
      </vx-card>
    </template>
  </div>
</template>

<script>
import SpkRepository from '@/repositories/proyek/spk-repository'
import printoutMixin from '@/utilities/mixins/printout-mixin'
import moduleSpkSHow from '@/store/modules/proyek/spk/spk-show.store'

export default {
  name: 'SpkShow',
  components: {
    TabHeader: () => import('@/views/pages/proyek/spk/tabs-show/TabHeader'),
    TabPekerjaan: () => import('@/views/pages/proyek/spk/tabs-show/TabPekerjaan'),
    TabAdjust: () => import('@/views/pages/proyek/spk/tabs-show/TabAdjust'),
    TabProgress: () => import('@/views/pages/proyek/spk/tabs-show/TabProgress'),
    TabPembayaran: () => import('@/views/pages/proyek/spk/tabs-show/TabPembayaran'),
    TabApprovals: () => import('@/views/pages/proyek/spk/tabs-show/TabApprovals')
  },
  mixins: [printoutMixin],
  mounted () {
    this.getInitData()
  },
  data () {
    return {
      isDataInited: false,
      isLoading: false
    }
  },
  computed: {
    storeSpkShow () {
      return this.$store.state.proyek.spkShow
    },
    activeTab: {
      get () {
        return this.storeSpkShow.activeTab
      },
      set (value) {
        this.$store.commit('proyek/spkShow/SET_ACTIVE_TAB', value)
      }
    },
    spkData: {
      get () {
        return this.storeSpkShow.spkData
      },
      set (value) {
        this.$store.commit('proyek/spkShow/SET_SPK_DATA', value)
      }
    },
    isApprovalOnProgress () {
      if (!this.spkData.header) return true
      return this.spkData.header.status_approval === 'PROSES'
    },
    isApprovalApproved () {
      if (!this.spkData.header) return false
      return this.spkData.header.status_approval === 'DITERIMA'
    }
  },
  methods: {
    getInitData () {
      this.isLoading = true

      const idSpk = this.$route.params.idSpk
      SpkRepository.show(idSpk)
        .then(response => {
          this.spkData = response.data.data
          this.isDataInited = true
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.notifyError('Data tidak ditemukan.')
          } else {
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    confirmDelete (id) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Yakin ingin menghapus data ini ?',
        acceptText: 'Hapus',
        cancelText: 'Batal',
        accept: () => { this.delete(id) }
      })
    },

    delete (id) {
      SpkRepository.delete(id)
        .then(response => {
          this.notifySuccess('Data berhasil terhapus')
          this.$router.push({ name: 'proyek.spk' })
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    printKontrak () {
      this.showPrintOutFile('SPP_KONTRAK', { id: this.$route.params.idSpk }) /* TODO: HARUS BREAKDOWN */
    }
  },
  beforeCreate () {
    this.$store.registerModule(['proyek', 'spkShow'], moduleSpkSHow)
  },
  beforeDestroy () {
    this.$store.commit('proyek/spkShow/RESET_STATE')
    this.$store.unregisterModule(['proyek', 'spkShow'])
  }
}
</script>
